<template>
    <div class="login wrapper is-vertical is-fullpage is-vcentered">
        <div class="logo">
            <img :src="logo" />
        </div>
        <div class="floating-box is-rounded has-shadow login-box is-vcentered">
            <div v-if="isChecking" class="checking">
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
            <div v-else class="login-form">
                <div v-if="!isLoggedIn" class="field">
                    <p class="control has-icons-left has-icons-right">
                        <input 
                            class="input is-rounded is-small" 
                            :class="{'is-success': isEmail && emailActive, 'is-warning': isEmail === false, 'is-danger': emailValid === false}"
                            type="email" 
                            placeholder="Email" 
                            v-model="email"
                            @input="checkEmail"
                        >
                        <span class="icon is-small is-left">
                            <i class="fas fa-envelope" ></i>
                        </span>
                        <span v-if="checkingEmail" class="icon is-small is-right">
                            <i :class="['fas', 'spinner', 'spin']"></i>
                        </span>
                        <span v-else-if="emailValid" class="icon is-small is-right is-success">
                            <i class="fas fa-check" ></i>
                        </span>
                        <span v-else-if="emailValid === false" class="icon is-small is-right is-danger">
                            <i class="fas fa-times-circle" ></i>
                        </span>
                    </p>
                    <div v-if="!signup && !isLoggedIn && emailValid === false" class="message is-rounded is-small" :key="0">
                        There's no user account with this email. Would you like to <router-link to="/join">sign up</router-link> instead?
                    </div>
                    <div v-else-if="signup && !isLoggedIn && emailValid === false" class="message is-rounded is-small" :key="4">
                        This email is already registered. Would you like to <router-link to="/login">login</router-link> instead?
                    </div>
                </div>
                <div v-if="!isLoggedIn && signup" class="field has-addons handle-field">
                    <div class="control">
                        <a class="button is-static is-small is-rounded">
                            thegood.link/
                        </a>
                    </div>
                    <div class="control has-icons-right">
                        <input 
                            class="input is-rounded is-small" 
                            :class="{'is-success': handleValid, 'is-danger': handleValid === false}"
                            type="text" 
                            placeholder="pagename" 
                            v-model="handle"
                            @input="checkHandle"
                        >
                        <span v-if="checkingHandle" class="icon is-small is-right">
                            <i :class="['fas', 'spinner', 'spin']" ></i>
                        </span>
                        <span v-else-if="handleValid" class="icon is-small is-right is-success">
                            <i class="fas fa-check" ></i>
                        </span>
                        <span v-else-if="handleValid === false" class="icon is-small is-right is-danger">
                            <i class="fas fa-times-circle" ></i>
                        </span>
                    </div>
                </div>
                <!-- <transition-group name="slideDown" tag="div" class="field"> -->
                <div v-if="showPassword && !isLoggedIn" class="field" :key="1">
                    <p class="control has-icons-left">
                        <input 
                            class="input is-rounded is-small" 
                            type="password" 
                            placeholder="Password (min. 10 chars)"
                            :disabled="!emailValid"
                            @keyup.enter="handleInputKeyUp('enter', 'password')"
                            v-model="password"
                        >
                        <span class="icon is-small is-left">
                            <i class="fas fa-lock" ></i>
                        </span>
                    </p>
                </div>
                <div v-if="signup && !isLoggedIn && showPassword && password && password.length > 9" class="field" :key="2">
                    <p class="control has-icons-left">
                        <input 
                            class="input is-rounded is-small" 
                            type="password" 
                            placeholder="Confirm Password"
                            :disabled="!emailValid"
                            v-model="confirmPassword"
                        >
                        <span class="icon is-small is-left">
                            <i class="fas fa-lock" ></i>
                        </span>
                    </p>
                </div>
                <!-- </transition-group> -->
                <div class="field">
                    <p v-if="isLoggedIn && user" class="control">
                        <button 
                            class="button is-danger is-rounded is-small" 
                            :class="{'is-loading': working }"
                            @click="logout()"
                        >
                            Logout
                        </button>
                    </p>
                    <p v-else-if="signup" class="control">
                        <button 
                            class="button is-info is-rounded is-small" 
                            :disabled="!readyToSignUp"
                            :class="{'is-loading': working }"
                            @click="signUp()"
                        >
                            Sign up
                        </button>
                    </p>
                    <p v-else class="control">
                        <button 
                            class="button is-success is-rounded is-small" 
                            :disabled="!readyToLogin"
                            :class="{'is-loading': working }"
                            @click="login()"
                        >
                            Login
                        </button>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { debounce } from "lodash-es";

export default {
    name: "Login",
    props: {
        signup: Boolean
    },
    data: function() {
        return {
            email: null,
            password: null,
            confirmPassword: null,
            handle: null,
            emailActive: null,
            checkingEmail: null,
            checkingHandle: null,
            handleTaken: null,
            working: null,
            tooltipOptions: {
                placement: 'bottom-end'
            }
        }
    },
    computed: {
        ...mapGetters({
            isChecking: "auth/isChecking",
            isLoggedIn: "auth/isLoggedIn",
            getLogo: "layout/getLogo",
            getUser: "user/getUser",
            afterAuth: "auth/getAfter"
        }),
        logo: function() {
            return this.getLogo.white
        },
        emailValid: function() {
            if(!this.email || this.emailActive === null || this.checkingEmail === true || !this.isEmail) return null;
            return this.signup ? !this.emailActive : this.emailActive;
        },
        isEmail: function() {
            if(!this.email || (this.email && this.email.length === 0)) return null;
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(this.email).toLowerCase());
        },
        handleValid: function() {
            if(!this.handle) return null;
            if(this.handleTaken === null) return null;

            return this.signup ? !this.handleTaken : this.handleTaken;
        },
        handleMessage: function() {
            var msg = "Please pick a unique handle for your page!";

            if(this.signup && this.handle) {
                msg = this.handleTaken ? "Sorry, this handle is already taken. Try something else!" : "Great, this handle is available!";
            }

            return { ...this.tooltipOptions, content: msg };
        },
        readyToLogin: function() {
            return !this.signup && this.isEmail && this.emailValid && this.password;
        },
        readyToSignUp: function() {
            return this.signup && this.isEmail && this.emailValid && this.passwordValid && this.passwordsMatch;
        },
        passwordValid: function() {
            if(!this.password) return null;
            return this.password.length >= 10;
        },
        passwordsMatch: function() {
            return (this.passwordValid && this.confirmPassword) && (this.password === this.confirmPassword);
        },
        showPassword: function() {
            if(this.isLoggedIn) return false;
            if(!this.isEmail) return false;
            if(!this.signup && !this.emailValid) return false;
            if(this.signup && (!this.emailValid || this.checkingHandle || !this.handleValid)) return false;
            return true;
        },
        credentials: function() {
            if(!this.email || !this.password) return null;

            return {
                email: this.email,
                password: this.password
            }
        },
        credentialsWithHandle: function() {
            if(!this.credentials || !this.handle) return null;

            return {
                ...this.credentials,
                handle: this.handle
            }
        },
        user: function() {
            return this.getUser
        }
    },
    methods: {
        ...mapActions({
            checkEmailActive: "auth/checkEmailActive",
            doCheckHandle: "auth/checkHandle",
            doSignUp: "auth/signUp",
            doLogin: "auth/doLogin",
            doLogout: "auth/doLogout"
        }),
        checkEmail: debounce(function() {
            if(!this.isEmail) return;

            this.checkingEmail = true;
            this.checkEmailActive(this.email)
            .then(active => {
                this.checkingEmail = false;
                this.emailActive = active;
            })
            .catch(err => {
                this.checkingEmail = false;
                console.error(err);
            })
        }, 500),
        checkHandle: debounce(function() {

            if(!this.handle) return;

            this.checkingHandle = true;
            this.doCheckHandle(this.handle)
            .then(checked => {
                console.log(this.handle, checked);
                if(checked.err) return console.error(checked.err);
                this.handleTaken = checked.handle || checked.handles;
                this.checkingHandle = false;
            })
            .catch(err => {
                this.checkingHandle = false;
                console.error(err);
            })
        }, 300),
        signUp: function() {
            if(!this.credentials && !this.credentialsWithHandle) return null;
            this.working = true;

            var signUpData = this.credentialsWithHandle || this.credentials;

            this.doSignUp(signUpData)
            .then(newUser => {
                console.log({newUser});
                this.working = false;
            })
            .catch(err => {
                console.error(err);
                this.working = false;
            })
        },
        login: function() {
            if(!this.credentials) return null;
            
            this.working = true;

            this.doLogin(this.credentials)
            .then(loggedInUser => {
                console.log({loggedInUser});
                this.working = false;
            })
            .catch(err => {
                console.error(err);
                this.working = false;
            })

        },
        logout: function() {
            if(!this.user) return false;
            this.working = true;

            this.doLogout()
            .then(() => {
                this.working = false;
            })
            .catch(err => {
                console.error(err);
                this.working = false;
            })

        },
        handleInputKeyUp(key, input) {
            if(key === "enter") {
                switch(input) {
                    case "password":
                        if(!this.signup) this.login();
                        break;
                }
            }
        }
    },
    mounted: function() {
        window.login = this;
        if(this.isLoggedIn) this.$router.push(this.afterAuth);
    },
    watch: {
        isLoggedIn: {
            immediate: true,
            handler(newVal) {
                if(newVal) this.$router.push(this.afterAuth);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .logo {
        max-width: 350px;
        width: 15vw;
        min-width: 120px;
        //position: absolute;
        margin-bottom: 20px;
        justify-self: flex-start;
    }

    .message {
        margin-top: 20px;
    }

    .handle-field {
        .button {
            font-weight: bold;
        }
    }

    .login-box {
        min-height: 100px;
    }
</style>